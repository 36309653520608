<template>
  <div
    :class="{
      wrapper: !device,
      wrappermobile: device,
    }"
  >
    <menu-ver
      type="course"
      :content="temas"
      :estado_curso="encurso_estado"
      v-on:theme="selectTheme"
      v-on:profesor="getMessages"
      v-on:material="seeMaterial"
      :id_emp="idemp"
      profesor="true"
    />
    <div class="m-3" :class="{ containerMobile: device, container: !device }">
      <div
        :class="{
          tableheader: !device,
          tableheadermobile: device,
        }"
      >
        <h1 v-if="messagesActive">
          {{ $t("mUser.courses.titleProfesor") }}
        </h1>
        <button
          v-if="messagesActive"
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#profesorModal"
          style="width: 140pt; background-color: crimson; color: white"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.sendMessage") }}
        </button>
      </div>

      <!-- Bloques temario -->
      <div v-if="selectedTheme">
        <div class="mb-5">
          <h3>{{ selectedTheme.tema_titulo }}</h3>
          <span>{{ selectedTheme.tema_descripcion }}</span>
        </div>
        <div class="mt-4" v-for="blo in bloques" :key="blo.bloque_id">
          <div class="card">
            <h4 class="bloque-title card-header">
              {{ blo.bloque_titulo }}
            </h4>
            <div class="card-body">
              <p class="card-text">{{ blo.bloque_descripcion }}</p>
              <button
                class="button"
                @click="openBlock(blo)"
                :style="{ backgroundColor: colorCompany }"
                v-if="blo.estado === 1"
              >
                <i class="fas fa-plus-circle"></i> {{ $t("actions.accBlock") }}
              </button>
              <button
                class="button"
                @click="openBlock(blo)"
                :style="{ backgroundColor: '#ff8888' }"
                v-if="blo.estado === 2"
              >
                <i class="fas fa-plus-circle"></i> {{ $t("actions.accBlock") }}
              </button>
              <button
                class="button"
                :style="{ backgroundColor: '#999' }"
                v-if="blo.estado === 0"
              >
                <i class="fas fa-plus-circle"></i> {{ $t("actions.accBlock") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Materiales -->
      <div v-if="selectedMaterial">
        <div class="mb-5 mt-5">
          <h3>{{ $t("mUser.course.material") }}</h3>
        </div>
        <div class="mt-4">
          <table class="table" v-if="materiales && materiales.length > 0">
            <thead>
              <tr>
                <th scope="col">{{ $t("maintenance.languages.name") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in materiales" :key="item.mate_id">
                <td>{{ item.mate_nombre }}</td>
                <td>
                  <a
                    class="btn button buttonEdit"
                    target="_blank"
                    :href="`${servidor}api/ver_archivo.asp?mate_id=${item.mate_id}`"
                  >
                    <i class="fas fa-download"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Mensajes -->
      <table class="table" v-if="messages && messages.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("phases.date") }}</th>
            <th scope="col">{{ $t("mUser.course.mensaje") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in messages" :key="item.mecu_id">
            <td>{{ item.mecu_fecha }}</td>
            <td>{{ item.mecu_texto }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal Bloque -->
  <div
    class="modal fade"
    id="blockModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-edit">
      <div class="modal-content" :style="{ height: heightModal }">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ bloque.bloque_titulo }}
          </h5>
          <a
            v-if="downloadFile"
            :href="downloadFile"
            target="_blank"
            class="button downloadButton"
            ><i class="fas fa-download"></i
          ></a>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="refreshCourse()"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="bloque.bloque_tipo === 'PDF'">
            <iframe
              :src="dataDoc"
              type="application/pdf"
              style="width: 100%"
              :style="{ height: heightObject }"
              id="docupdf"
              pluginspage="http://www.adobe.com/products/acrobat/readsetp2.html"
            ></iframe>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'OFFICE'">
            <iframe
              :src="dataDoc"
              style="width: 100%"
              :style="{ height: heightObject }"
              frameborder="0"
            ></iframe>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'EXAM'">
            <iframe
              :src="dataDoc"
              style="width: 100%"
              :style="{ height: heightObject }"
              frameborder="0"
            ></iframe>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'VIDEO'">
            <video
              controls
              id="myVideo"
              style="width: 100%"
              :style="{ height: heightObject }"
            >
              <source :src="dataDoc" :type="tipoVideo" />
            </video>
          </div>
          <div v-else-if="bloque.bloque_tipo === 'YOUTUBE'">
            <iframe
              style="width: 100%"
              :style="{ height: heightObject }"
              :src="bloque.bloque_ruta_youtube"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div
            v-else-if="bloque.bloque_tipo === 'ALUMNO'"
            style="text-align: center"
          >
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="mb-3" v-if="downloadFile">
                  <div class="mb-3">
                    {{ $t("mUser.course.workStudent") }}
                  </div>
                  <div class="mb-3">
                    * {{ $t("mUser.course.problemProf") }}
                  </div>
                  <a
                    :href="downloadFile"
                    target="_blank"
                    class="button"
                    style="width: 100%"
                    :style="{ backgroundColor: colorCompany }"
                    ><i class="fas fa-download"></i
                  ></a>
                </div>
                <div class="mb-3" v-else>
                  <div class="mb-3">
                    {{ bloque.bloque_descripcion }}
                  </div>
                  <FilePond
                    ref="pondAdd"
                    :label-idle="imgText"
                    class="imgFile"
                    max-files="1"
                  />
                  <button
                    class="button"
                    style="width: 100%"
                    :style="{ backgroundColor: colorCompany }"
                    @click="sendFAlumno()"
                  >
                    {{ $t("actions.send") }}
                  </button>
                </div>
              </div>
              <div class="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Profe -->
  <div
    class="modal fade"
    id="profesorModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.course.profesorTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="refreshCourse()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.course.mensaje`) + " *"
              }}</label>
              <textarea
                style="text-transform: uppercase"
                rows="7"
                class="form-control"
                id="FormName"
                v-model.trim="profe_mensaje"
                maxlength="255"
                :placeholder="$t(`mUser.course.mensaje`)"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="sendEmail"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanProfe()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="result === '0'"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="result !== '0'"
            class="btn button btn-return-result"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            @click="returnModal()"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  components: {
    MenuVer,
    FilePond,
  },
  data() {
    return {
      course: {},
      id: this.$route.params.id,
      idemp: this.$route.params.idemp,
      temas: [],
      bloques: [],
      messages: [],
      bloque: {},
      selectedTheme: null,
      dataDoc: "",
      tipoVideo: "",
      heightModal: "",
      heightObject: "",
      downloadFile: null,
      profe_mensaje: "",
      selectedMaterial: false,
      materiales: [],
      result: "",
      selectedMaterial: false,
      formError: false,
      messagesActive: false,
      encurso_estado: "",
      imgText: this.$t("actions.imgText"),
      profOrAlumn: false,
    };
  },
  created() {
    this.getThemes();
    this.getTitleCurse();
    this.changeBreadCrumb(13);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor", "colorCompany", "device"]),
  },
  mounted() {
    this.matchHeight();
    window.addEventListener("resize", this.matchHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.matchHeight);
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop", "changeTitleCourse"]),
    async getTitleCurse() {
      try {
        const listado = {
          curso_id: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=curso&ac=titulo`,
          listado
        );
        // console.log(res);
        let title = "";
        if (res.data) {
          title = res.data.data[0].curso_titulo;
          this.encurso_estado = res.data.data[0].encurso_estado;
        }
        // console.log(title);
        this.changeTitleCourse(title);
      } catch (error) {
        console.error(error);
      }
    },
    async getMessages() {
      this.selectedTheme = false;
      this.selectedMaterial = false;
      this.messagesActive = true;
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=curso&ac=listadoMensajes&curso=${this.id}`
        );
        // console.log("mismensajes", res);
        if (res.data) {
          this.students = [];
          this.messages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    matchHeight() {
      const w = window.innerHeight - 77;
      const y = window.innerHeight - 187;
      // console.log("altura", w);
      this.heightModal = w.toString() + "px";
      this.heightObject = y.toString() + "px";
      // console.log(this.heightModal);
    },
    async getThemes() {
      try {
        const listado = {
          tema_curso_cd: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=curso&ac=temas`,
          listado
        );
        if (res.data) {
          this.temas = res.data.data;
          if (this.temas) {
            for (let i = 0; i < this.temas.length; i++) {
              if (this.temas[i].estado == 1) {
                this.selectedTheme = this.temas[i];
                this.getBlocks();
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getBlocks() {
      // console.log("INBLOCKS")
      try {
        const listado = {
          bloque_tema_cd: this.selectedTheme.tema_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=curso&ac=bloques`,
          listado
        );
        if (res.data) {
          this.bloques = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getMaterial() {
      try {
        const listado = {
          mate_curso_cd: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=material&ac=listado`,
          listado
        );
        if (res.data) {
          this.materiales = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    seeCurse(id) {
      // console.log(id);
    },
    selectTheme(item) {
      this.messagesActive = false;
      this.selectedMaterial = false;
      this.messages = [];
      this.selectedTheme = item;
      this.getBlocks();
    },
    openBlock(bloque) {
      this.bloque = bloque;
      this.dataDoc = null;
      this.downloadFile = null;
      // console.log("BLOQUE", bloque);
      if (this.bloque.bloque_tipo === "PDF") {
        this.dataDoc = `${this.servidor}ver_pdf.asp?t=bloqprof&p=${bloque.bloque_id} #toolbar=0`;
        this.downloadFile = `${this.servidor}ver_pdf.asp?t=bloqprof&p=${bloque.bloque_id}`;
      } else if (this.bloque.bloque_tipo === "OFFICE") {
        // FALTA TAMBIÉN REFRESCAR TEMAS Y BLOQUES AL CERRAR EL MODAL. BLOQUES CREO QUE OK PERO TEMAS CREO
        // QUE ES MÁS COMPLICADO        
        const sUrlEnc = `${this.servidor}cursos/${bloque.bloque_fichero}`;
        this.downloadFile = `${this.servidor}descargar_office.asp?t=bloqprof&p=${bloque.bloque_id}`;
        this.dataDoc = `https://view.officeapps.live.com/op/embed.aspx?src=${sUrlEnc}`;
        this.verOffice(bloque.bloque_id);
      } else if (this.bloque.bloque_tipo === "EXAM") {
        this.dataDoc = `${this.servidor}ver_examen.asp?t=bloqprof&p=${bloque.bloque_id}`;
        this.downloadFile = null;
      } else if (this.bloque.bloque_tipo === "VIDEO") {
        this.dataDoc = `${this.servidor}ver_video.asp?t=bloqprof&p=${bloque.bloque_id}`;
        this.downloadFile = `${this.servidor}ver_pdf.asp?t=bloqprof&p=${bloque.bloque_id}`;
        if (bloque.bloque_fichero.includes(".mp4")) {
          this.tipoVideo = "video/mp4";
        } else if (bloque.bloque_fichero.includes(".mov")) {
          this.tipoVideo = "video/quicktime";
        } else if (bloque.bloque_fichero.includes(".avi")) {
          this.tipoVideo = "video/x-msvideo";
        } else if (bloque.bloque_fichero.includes(".wmv")) {
          this.tipoVideo = "video/x-ms-wmv";
        } else if (bloque.bloque_fichero.includes(".3gp")) {
          this.tipoVideo = "video/3gpp";
        } else if (bloque.bloque_fichero.includes(".webm")) {
          this.tipoVideo = "video/webm";
        } else if (bloque.bloque_fichero.includes(".ogg")) {
          this.tipoVideo = "video/ogg";
        }
      } else if (this.bloque.bloque_tipo === "ALUMNO") {
        if (this.bloque.estado === 2) {
          this.downloadFile = `${this.servidor}ver_trabajo.asp?bloque_id=${this.bloque.bloque_id}`;
        } else {
          this.downloadFile = null;
        }
      } else if (this.bloque.bloque_tipo === "YOUTUBE") {
        this.verYoutube(bloque.enbloque_id, bloque.bloque_id);
      }
      $("#blockModal").modal("show");
    },
    async verYoutube(id, bloque_id) {
      // console.log("YOUTUBE", id + ", bloq: " + bloque_id)
      try {
        const res = await axios.post(   
          `${this.servidor}api/op_usu.asp?tp=bloque&ac=youtube&id=${id}&bloque_id=${bloque_id}`
        );
      // console.log("office", res);
      } catch (error) {
        console.error(error);
      }
    },
    async verOffice(id) {
      const res = await axios.post(
        `${this.servidor}ver_office.asp?t=bloqprof&p=${id}`
      );
      // console.log("office", res);
    },
    async sendFAlumno() {
      try {        
        let formData = new FormData();
        if (this.$refs && this.$refs.pondAdd && this.$refs.pondAdd.getFile(0)) {
          // console.log("enbloque_fichero", this.$refs.pondAdd.getFile(0).file);
          formData.append(
            "enbloque_fichero",
            this.$refs.pondAdd.getFile(0).file
          );
        }
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=bloque&ac=fichero&bloque_id=${this.bloque.bloque_id}`,
          formData
        );
        // console.log(res);
        this.result = res.data.RESULT;
        this.profOrAlumn = false;
        $("#blockModal").modal("hide");
        $("#responseModal").modal("show");
      } catch {
        this.result = "1";
        $("#blockModal").modal("hide");
        $("#responseModal").modal("show");
      }
      this.refreshCourse();
    },
    refreshCourse() {
      this.getThemes();
      this.getBlocks();
      let video = document.getElementById("myVideo");
      if (video) {
        video.pause();
      }      
    },
    async sendEmail() {
      if (this.validateForm()) {
        const mensaje = {
          mecu_texto: this.profe_mensaje.toUpperCase(),
          mecu_curso_cd: this.id,
          empresa_id: this.idemp,
        };
        // console.log("mensaje", mensaje);
        try {
          const res = await axios.post(
            `${this.servidor}api/op_usu.asp?tp=contactar`,
            mensaje
          );
          if (res.data) {
            this.result = res.data.RESULT;
          } else {
            this.result = "1";
          }
          this.profOrAlumn = true;   
          $("#profesorModal").modal("hide");
          $("#responseModal").modal("show");       
        } catch (error) {
          console.error(error);
        }        
        this.cleanProfe();
      }
    },
    cleanProfe() {
      this.profe_mensaje = "";
      this.formError = false;
      this.getMessages();
    },
    validateForm() {
      if (this.profe_mensaje === "") {
        this.formError = true;
        return false;
      }
      return true;
    },
    seeMaterial() {
      this.messagesActive = false;
      this.selectedMaterial = true;
      this.selectedTheme = false;
      this.messages = [];
      this.getMaterial();
    },
    async downloadCertificate() {
      let doc;
      if (this.servidor.includes("comunica")) {
        doc = new jsPDF("p", "pt", "A4");
      } else {
        doc = new jsPDF("p", "pt", "A4");
      }

      // console.log(this.id);
      const res = await axios.post(
        `${this.servidor}api/export.asp?id=${this.id}`
      );

      doc.html(res.data, {
        x: 10,
        y: {
          width: 10,
          elementHandlers: {
            "#bypassme": function (element, renderer) {
              return true;
            },
          },
        },
        callback: function (doc) {
          doc.save();
        },
      });

      this.tableToExport = false;
    },
    returnModal() {
      if (this.profOrAlumn) {
        $("#responseModal").modal("hide");
        $("#profesorModal").modal("show");
      } else {
        $("#responseModal").modal("hide");
        $("#blockModal").modal("show");
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bloque-title {
  cursor: pointer;
}

.modal-edit {
  width: 99%;
  max-width: none;
}

.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.downloadButton {
  border: 2px solid;
  margin-left: 20pt;
}

.containerMobile {
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>